import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
  kitle: false,
  kitlenesne: false,
  selectedValue: 'text',
  positionValues: [],
  positionValuesImages: [],
  positionValuesQR: [],
  arkaplan: '',
  barkode: 'test',
  formValues: [{ id:null,alanname: '', boyut: '', renk: '', kalinlik: false, x: null, y: null, tipi: '', genislik: '', yukseklik: '', kilit: false }],
  lastPositions: {},
  formValuesimages: [{ dosyayolu: '',  kilit: false, x: 0, y: 0, en: 100, boy: 100, tipi: '', genislik: '', yukseklik: '' }],
  formBarcodes: [{ code: '', kilit: false, x: null, y: null, tipi: '', en: '', boy: '' }],
  selectedCode: 'DEFAULT',
};

export const updateFormValues = createAsyncThunk('userislem/updateFormValues', async (newFormValues) => {
  return newFormValues;
});

const userislemSlice = createSlice({
  name: 'userislem',
  initialState,
  reducers: {
    setKitle: (state, action) => {
      state.kitle = action.payload;
    },
    setKitlenesne: (state, action) => {
      state.kitlenesne = action.payload;
    },
    setSelectedValue: (state, action) => {
      state.selectedValue = action.payload;
    },
    setPositionValues: (state, action) => {
      state.positionValues = action.payload;
    },
    setPositionValuesImages: (state, action) => {
      state.positionValuesImages = action.payload;
    },
    setPositionValuesQR: (state, action) => {
      state.positionValuesQR = action.payload;
    },
    setArkaplan: (state, action) => {
      state.arkaplan = action.payload;
    },
    setBarkode: (state, action) => {
      state.barkode = action.payload;
    },
    setFormValues: (state, action) => {
      state.formValues = action.payload;
    },
    setFormValuesimages: (state, action) => {
      state.formValuesimages = action.payload;
    },
    setFormBarcodes: (state, action) => {
      state.formBarcodes = action.payload;
    },
    setSelectedCode: (state, action) => {
      state.selectedCode = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateFormValues.fulfilled, (state, action) => {
      state.formValues = action.payload;
    });
  },
});

export const {
  setKitle,
  setKitlenesne,
  setSelectedValue,
  setPositionValues,
  setPositionValuesImages,
  setPositionValuesQR,
  setArkaplan,
  setBarkode,
  setFormValues,
  setFormValuesimages,
  setFormBarcodes,
  setSelectedCode,
} = userislemSlice.actions;

export default userislemSlice.reducer;
