import React, { useState, useEffect, useCallback } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Login from "./components/Login";
//import Register from "./components/Admin/Register";
import Home from "./components/Home";
import Profile from "./components/Profile";
import BoardUser from "./components/User/BoardUser";
import BoardAdmin from "./components/Admin/BoardAdmin";
import { logout } from "../src/slices/auth";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import EventBus from "../src/components/common/EventBus";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";

// layouts

import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";

// views without layouts

import Landing from "./views/Landing.js";
import Profiles from "views/Profile.js";
import Index from "./views/Index.js";
function NotFound() {
  return (
    <div>
      <h1>404 Not Found</h1>
      <p>The page you requested could not be found.</p>
      <Link to="/home">Go back to the homepage</Link>
    </div>
  );
}
function App() {
  const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);

  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    if (currentUser) {
      setShowModeratorBoard(currentUser.roles.includes("ROLE_MODERATOR")); //
      setShowAdminBoard(currentUser.roles.includes("ROLE_ADMIN"));
    } else {
      setShowModeratorBoard(false);
      setShowAdminBoard(false);
    }

    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, [currentUser, logOut]);


  return (
    <Router>
      <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <Link to={"/"} className="navbar-brand">
            Priso
          </Link>
          <div className="navbar-nav mr-auto">
            {/*      <li className="nav-item">
            <Link to={"/home"} className="nav-link">
              Home
            </Link>
          </li> */}

            {showModeratorBoard && (
              <li className="nav-item">
                <Link to={"/mod"} className="nav-link">
                  Moderator Board
                </Link>
              </li>
            )}

            {showAdminBoard && (
              <li className="nav-item">
                <Link to={"/admin"} className="nav-link">
                  Admin Board
                </Link>
              </li>
            )}

            {currentUser && (
              <li className="nav-item">
                <Link to={"/user"} className="nav-link">
                  User
                </Link>
              </li>
            )}
          </div>

          {currentUser ? (
            <div className="navbar-nav ml-auto">
              {/*  <li className="nav-item">
                <Link to={"/profile"} className="nav-link">
                  {currentUser.username}
                </Link>
              </li> */}
              <li className="nav-item">
                <a href="/login" className="nav-link" onClick={logOut}>
                  LogOut
                </a>
              </li>
            </div>
          ) : (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={"/login"} className="nav-link">
                  Login
                </Link>
              </li>

              {/*   <li className="nav-item">
              <Link to={"/register"} className="nav-link">
                Sign Up
              </Link>
            </li> */}
            </div>
          )}
        </nav>

        <div className="container mt-3">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/login" element={<Login />} />
            {/*  <Route path="/register" element={<Register />} /> */}
            {/*   <Route path="/profile" element={<Profile />} /> */}
            <Route path="/user" element={<BoardUser />} />
            {/*   <Route path="/mod" element={<BoardModerator />} /> */}
            <Route path="/admin" element={<BoardAdmin />} />

            {/*      <Route path="/admin" element={Admin} />
            <Route path="/auth" element={Auth} /> */}
            <Route path="/landing" exact element={Landing} />
            <Route component={NotFound} />
            {/*   <Route path="/profile" exact element={Profile} /> */}

          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
