import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import authReducer from './slices/auth';
import messageReducer from './slices/message';
import userislemReducer from './slices/userislem';
import thunk from 'redux-thunk';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  storage
};

const rootReducer = combineReducers({
  auth: authReducer,
  message: messageReducer,
  userislem: userislemReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

 const myStore = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

const persistor = persistStore(myStore);

export { myStore, persistor };
