import axios from "axios";
import authHeader from "./auth-header";

 
const getPublicContent = () => {
  return axios.get("https://prisoapi.madilink.net/api/test/all");
};

const getUserBoard = () => {
  return axios.get("https://prisoapi.madilink.net/api/test/user", { headers: authHeader() });
};

const getModeratorBoard = () => {
  return axios.get( "https://prisoapi.madilink.net/api/test/moderator", { headers: authHeader() });
};

const getAdminBoard = () => {
  return axios.get("https://prisoapi.madilink.net/api/test/admin", { headers: authHeader() });
};

const userService = {
  getPublicContent,
  getUserBoard,
  getModeratorBoard,
  getAdminBoard,
};

export default userService