/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useRef, useEffect, Component } from "react";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import { useSelector, useDispatch } from 'react-redux';
import { Editor } from "react-draft-wysiwyg";
import Dropdown from 'react-bootstrap/Dropdown';
import { useReactToPrint } from "react-to-print";
import ComponentToPrint from "./ComponentToPrint/index"
import Barcode from 'jsbarcode-react';
import QRCode from "react-qr-code";
import "./PrintCss.css"
import "./card.scss"
import bwipjs from 'bwip-js';
//import Barcodes from "./Barcodes"
import { setFormValues, setFormValuesimages, setFormBarcodes, setArkaplan, setSelectedValue, setKitle, setKitlenesne, setSelectedCode } from '../../../src/slices/userislem';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import xmlBuilder from 'xmlbuilder';

 

export default function Dashboard() {
  const kitle = useSelector(state => state.userislem.kitle);
  const kitlenesne = useSelector(state => state.userislem.kitlenesne);
  const selectedValue = useSelector(state => state.userislem.selectedValue);
  // const positionValues = useSelector(state => state.userislem.positionValues);
  // const positionValuesImages = useSelector(state => state.userislem.positionValuesImages);
  // const positionValuesQR = useSelector(state => state.userislem.positionValuesQR);
  const arkaplan = useSelector(state => state.userislem.arkaplan);
  // const barkode = useSelector(state => state.userislem.barkode);
  const formValues = useSelector(state => state.userislem.formValues);
  const formValuesimages = useSelector(state => state.userislem.formValuesimages);
  const formBarcodes = useSelector(state => state.userislem.formBarcodes);
  const selectedCode = useSelector(state => state.userislem.selectedCode);
  const dispatch = useDispatch();
  const componentRef = useRef();
  // const [position, setPosition] = useState({});
  // const [images, setImages] = useState([]);
  const STORAGE_KEY = "lastPositions";
  const STORAGE_KEY_IMAGE = "lastPositionsimage";
  const STORAGE_KEY_QRCODE = "lastPositionsQrcode";
  const fileInputRef = useRef(null);

  const [lastPositions, setLastPositions] = useState(() => {
    const storedPositions = localStorage.getItem(STORAGE_KEY);
    return storedPositions ? JSON.parse(storedPositions) : [];
  });
  const [lastPositionsimage, setLastPositionsimage] = useState(() => {
    const storedPositionsimage = localStorage.getItem(STORAGE_KEY_IMAGE);
    return storedPositionsimage ? JSON.parse(storedPositionsimage) : [];
  });
  const [lastPositionsqrcode, setLastPositionsarcode] = useState(() => {
    const storedPositionsarcode = localStorage.getItem(STORAGE_KEY_QRCODE);
    return storedPositionsarcode ? JSON.parse(storedPositionsarcode) : [];
  });
  function handleConvertClick() {
    html2canvas(componentRef.current, { useCORS: true }).then((canvas) => {
      // Canvas'taki içeriği JPG formatında bir resme dönüştür
      const dataURL = canvas.toDataURL('image/jpeg');

      // Resmi indir
      const link = document.createElement('a');
      link.download = 'component.jpg';
      link.href = dataURL;
      link.click();
    });
  }
  const [bounds, setBounds] = useState({});
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
    @page {
      size: 85.60mm 53.98mm;
      margin: 0;
      padding: 0;
    }
    body {
      width: 85.60mm;
      height: 53.98mm;
    }
    @media print {
      body {
        margin: 0;
        padding: 0;
      }
    }
  `,
  });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      dispatch(setWindowWidth(window.innerWidth));
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setBounds({
      left: 1,
      right: 295,
      top: -1,
      bottom: 240,
    });
  }, [windowWidth]);
  const dragHandlers = {
    onStart: (e, data) => {
    },
    onStop: (e, data) => {
    },
    onDrag: (e, data) => {
      const { name, type } = e.target.dataset;
      const newFormValues = formValues.map(element => {
        if (element.alanname === name) {
          return {
            ...element,
            x: data.x,
            y: data.y
          };
        }
        return element;
      });
      dispatch(setFormValues(newFormValues));
      console.log(formValues)
    }
  };
  const dragHandlersimage = {
    onStart: (e, data) => {
    },
    onStop: (e, data) => {
    },
    onDrag: (e, data) => {
      const { name, type } = e.target.dataset;
      const newFormValues = formValuesimages.map(element => {
        if (element.alanname === name) {
          return {
            ...element,
            x: data.x,
            y: data.y
          };
        }
        return element;
      });
      dispatch(setFormValuesimages(newFormValues));
      console.log(formValuesimages)
    }
  };
  const dragHandlersQR = {
    onStart: (e, data) => {
    },
    onStop: (e, data) => {
    },
    onDrag: (e, data) => {
      const { name, type } = e.target.dataset;
      const newFormValues = formBarcodes.map(element => {
        if (element.code === name) {
          return {
            ...element,
            x: data.x,
            y: data.y
          };
        }
        return element;
      });
      dispatch(setFormBarcodes(newFormValues));
      console.log(newFormValues)
    }
  };
  function handleSubmitreadfile(event) {
    event.preventDefault();

    const formValues = [];
    const formValuesimages = [];
    const formBarcodes = [];

    if (fileInputRef.current.files.length > 0) {
      const file = fileInputRef.current.files[0];
      const reader = new FileReader();

      reader.onload = (event) => {
        console.log('File loaded successfully');

        const xml = event.target.result;
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xml, "text/xml");

        const yazilar = xmlDoc.getElementsByTagName("Yazi");
        const resimler = xmlDoc.getElementsByTagName("Resim");
        const barkodlar = xmlDoc.getElementsByTagName("Barkod");

        // Read Yazilar
        for (let i = 0; i < yazilar.length; i++) {
          const { alanname, boyut, renk, kalinlik, x, y, kilit } = yazilar[i].attributes;
          formValues.push({
            alanname: alanname?.value || "",
            boyut: isNaN(parseInt(boyut?.value)) ? 0 : parseInt(boyut?.value),
            renk: renk?.value === "true",
            kalinlik: kalinlik?.value === "true",
            x: isNaN(parseInt(x?.value)) ? 0 : parseInt(x?.value),
            y: isNaN(parseInt(y?.value)) ? 0 : parseInt(y?.value),
            kilit: kilit?.value === "true",
          });
        }

        // Read Resimler
        for (let i = 0; i < resimler.length; i++) {
          const { dosyayolu, kilit, x, y, en, boy, tipi, genislik, yukseklik } = resimler[i].attributes;
          formValuesimages.push({
            dosyayolu: dosyayolu.value,
            kilit: kilit.value === "true",
            x: isNaN(parseInt(x.value)) ? 0 : parseInt(x.value),
            y: isNaN(parseInt(y.value)) ? 0 : parseInt(y.value),
            en: isNaN(parseInt(en.value)) ? 0 : parseInt(en.value),
            boy: isNaN(parseInt(boy.value)) ? 0 : parseInt(boy.value),
            tipi: tipi.value,
            genislik: genislik.value,
            yukseklik: yukseklik.value,
          });
        }

        // Read Barkodlar
        for (let i = 0; i < barkodlar.length; i++) {
          const { code, kilit, tipi, en, boy } = barkodlar[i].attributes;
          formBarcodes.push({
            code: code?.value || "",
            kilit: kilit?.value === "true",
            tipi: tipi?.value || "",
            en: isNaN(parseInt(en?.value)) ? 0 : parseInt(en?.value),
            boy: isNaN(parseInt(boy?.value)) ? 0 : parseInt(boy?.value),
          });
        }

        dispatch(setFormValues(formValues));
        dispatch(setFormValuesimages(formValuesimages));
        dispatch(setFormBarcodes(formBarcodes));
      };

      console.log('File loaded successfully');

      reader.readAsText(file);
    } else {
      console.log("No file selected");
    }
  }
  const handleTextStop = (index, position) => {
    const newPositions = [...lastPositions];
    newPositions[index] = position;
    setLastPositions(newPositions);
    localStorage.setItem(STORAGE_KEY, JSON.stringify(newPositions));
  };
  const handleTextStopimage = (index, position) => {
    const newPositionsimage = [...lastPositionsimage];
    newPositionsimage[index] = position;
    setLastPositionsimage(newPositionsimage);
    localStorage.setItem(STORAGE_KEY_IMAGE, JSON.stringify(newPositionsimage));
  };
  const handleBarcodeStop = (index, position) => {
    const newPositionsqrcode = [...lastPositionsqrcode];
    newPositionsqrcode[index] = position;
    setLastPositionsarcode(newPositionsqrcode);
    localStorage.setItem(STORAGE_KEY_QRCODE, JSON.stringify(newPositionsqrcode));
  };
  let handleChange = (index, name, value) => {
    let newFormValues = [...formValues];
    newFormValues[index] = { ...newFormValues[index], [name]: value };
    dispatch(setFormValues(newFormValues));
    console.log(newFormValues);
  };
  let handleChangebarcode = (index, name, value, selectedCode) => {

    console.log("değerler " + value)
    console.log("Code: " + selectedCode)

    let newFormValuesbarcodes = [...formBarcodes];
    newFormValuesbarcodes[index] = { ...newFormValuesbarcodes[index], [name]: value };

    if (selectedCode === "ean13" && value.length !== 13) {
      console.log("EAN-13 barkodu 13 karakterli olmalıdır.");
    } else {
      dispatch(setFormBarcodes(newFormValuesbarcodes));
      console.log(newFormValuesbarcodes);
    }
  };
  const handleCheckboxChange = (index) => {
    let newFormValues = [...formValues];
    newFormValues[index] = { ...newFormValues[index], kalinlik: !newFormValues[index].kalinlik };
    dispatch(setFormValues(newFormValues));
    console.log(newFormValues);
  };
  let handleChangeimages = (index, event) => {
    const { name, value, type, checked } = event.target;
    const newFormValues = [...formValuesimages];
    const element = { ...newFormValues[index] };

    if (type === "checkbox") {
      element[name] = checked;
    } else {
      element[name] = value;
    }

    newFormValues[index] = element;
    dispatch(setFormValuesimages(newFormValues));
  }
  function arkaplanayarla(event) {
    event.preventDefault();
    //setarkaplan(event.target.value);
    dispatch(setArkaplan(event.target.value));
    console.log(arkaplan)
  }
  function handleChangeradiobutton(event) {
    dispatch(setSelectedValue(event.target.value));
    console.log(selectedValue)

  }
  const addFormFields = () => {
    return (dispatch, getState) => {
      const { formValues } = getState().userislem;

      const newObject = {
        alanname: "", boyut: "", renk: "", kalinlik: false, x: "", y: "", tipi: "", genislik: "", yukseklik: ""
      };

      const newFormValues = [...formValues, newObject];

      dispatch(setFormValues(newFormValues));
    };
  };
  let addFormBarcodeFields = () => {
    return (dispatch, getState) => {
      const { formBarcodes } = getState().userislem;
      const newObject = {
        code: "", kilit: false, en: "", boy: "", tipi: "", genislik: "", yukseklik: ""
      }
      const newFormValuesqr = [...formBarcodes, newObject];
      dispatch(setFormBarcodes(newFormValuesqr));
    };
  }
  const addFormFieldsimages = () => {
    return (dispatch, getState) => {
      const { formValuesimages } = getState().userislem;

      const newObject = {

        dosyayolu: "", en: "", boy: "", kilit: false, x: "", y: "", tipi: "", genislik: "", yukseklik: ""
      }

      const newFormValuesimages = [...formValuesimages, newObject];

      dispatch(setFormValuesimages(newFormValuesimages));
    };
  };
  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    dispatch(setFormValues(newFormValues))
  }
  let removeFormFieldsAll = () => {
    let newFormValues = [...formValues];
    dispatch(setFormValues(newFormValues))
  }
  let removeFormFieldsbarcodes = (i) => {
    let newFormValuesbarcodes = [...formBarcodes];
    newFormValuesbarcodes.splice(i, 1);
    dispatch(setFormBarcodes(newFormValuesbarcodes))
  }
  const handleCheckboxChangeKilitlimi = (event) => {
    dispatch(setKitle(event.target.checked));
    console.log("kilit durumu: " + event.target.checked);
  };
  const handleCheckboxChangeImageNesne = (index) => {

    let newFormValuesimages = [...formValuesimages];
    newFormValuesimages[index].kilit = !newFormValuesimages[index].kilit;
    dispatch(setKitlenesne(newFormValuesimages));
    console.log(newFormValuesimages);
    //setKitlenesne(event.target.checked);
    // console.log("kilit durumu :" + kitlenesne)
  };
  const handleCheckboxChangeBarcodeNesne = (index) => {
    let newFormValuesbarcodess = [...formBarcodes];
    newFormValuesbarcodess[index].kilit = !newFormValuesbarcodess[index].kilit;
    dispatch(setKitlenesne(newFormValuesbarcodess));
    console.log(newFormValuesbarcodess);
    //setKitlenesne(event.target.checked);
    // console.log("kilit durumu :" + kitlenesne)
  };
  const handleCheckboxChangeNesne = (index) => {

    let newFormValues = [...formValues];
    newFormValues[index] = { ...newFormValues[index], kilit: !newFormValues[index].kilit };
    dispatch(setKitlenesne(newFormValues));
    console.log(newFormValues);

  };
  let removeFormFieldsimages = (i) => {
    let newFormValuesimages = [...formValuesimages];
    newFormValuesimages.splice(i, 1);
    dispatch(setFormValuesimages(newFormValuesimages))
  }
  const submit = (e) => {
    e.preventDefault();
    console.log(formValues)
  }
  const handleCodeSelection = (selectedCode) => {
    dispatch(setSelectedCode(selectedCode));
    console.log(selectedCode)
  };
  const Datamatrix = ({ value, scale, height, width }) => {
    const canvasRef = useRef(null);

    useEffect(() => {
      if (canvasRef.current) {
        bwipjs.toCanvas(canvasRef.current, {
          bcid: "datamatrix",
          text: value,
          scale: scale,
          height: height,
          width: width,
          includetext: true,
          textxalign: "center",
        });
      }
    }, [height, scale, value, width]);

    return <canvas ref={canvasRef} />;
  };
  const EAN13 = ({ value, scale, height, width }) => {
    const canvasRef = useRef(null);

    useEffect(() => {
      if (canvasRef.current) {
        bwipjs.toCanvas(canvasRef.current, {
          bcid: "ean13",
          text: value,
          scale: scale,
          height: height,
          width: width,
          includetext: true,
          textxalign: "center",
        });
      }
    }, [height, scale, value, width]);

    return <canvas ref={canvasRef} />;
  };
  const Code39 = ({ value, scale, height, width }) => {
    const canvasRef = useRef(null);

    useEffect(() => {
      if (canvasRef.current) {
        bwipjs.toCanvas(canvasRef.current, {
          bcid: "code39",
          text: value,
          scale: scale,
          height: height,
          width: width,
          includetext: true,
          textxalign: "center",
        });
      }
    }, [height, scale, value, width]);

    return <canvas ref={canvasRef} />;
  };
  const Code128 = ({ value, scale, height, width }) => {
    const canvasRef = useRef(null);

    useEffect(() => {
      if (canvasRef.current) {
        bwipjs.toCanvas(canvasRef.current, {
          bcid: "code128",
          text: value,
          scale: scale,
          height: height,
          width: width,
          includetext: true,
          textxalign: "center",
        });
      }
    }, [height, scale, value, width]);

    return <canvas ref={canvasRef} />;
  };
  const Qrcodum = ({ value, scale, height, width }) => {
    const canvasRef = useRef(null);

    useEffect(() => {
      if (canvasRef.current) {
        bwipjs.toCanvas(canvasRef.current, {
          bcid: "qrcode",
          text: value,
          scale: scale,
          height: height,
          width: width,
          includetext: true,
          textxalign: "center",
        });
      }
    }, [height, scale, value, width]);

    return <canvas ref={canvasRef} />;
  };
  const isValidCode = (code) => {
    return /^[0-9a-zA-Z]+$/.test(code);
  }
  const isValidCode128 = (code) => {
    return /^[0-9a-zA-Z\s\-.$/+%]+$/.test(code);
  }
  const isValidCodeJustNumber = (code) => {
    return /^[0-9A-Z\s\-.$/+%]+$/.test(code);
  }
  function clearFormValues() {
    let newFormValues = [{ id: null, alanname: '', boyut: '', renk: '', kalinlik: false, x: null, y: null, tipi: '', genislik: '', yukseklik: '', kilit: false }];
    let newFormValuesimages = [{ dosyayolu: '', kilit: false, x: 0, y: 0, en: 0, boy: 0, tipi: '', genislik: '', yukseklik: '' }];
    let newFormValuesbarcodes = [{ code: '', kilit: false, x: null, y: null, tipi: '', en: '', boy: '' }];

    //newFormValues.splice(formValues.length, 1);
    dispatch(setFormValuesimages(newFormValuesimages))
    dispatch(setFormBarcodes(newFormValuesbarcodes))
    dispatch(setFormValues(newFormValues))
  }
  function createXML() {
    const root = xmlBuilder.create('root');

    const yazilarNode = root.ele('Yazilar');
    formValues.forEach((element) => {
      const { alanname, boyut, renk, kalinlik, x, y, tipi, genislik, yukseklik, kilit } = element;
      yazilarNode.ele('Yazi', {
        alanname,
        boyut,
        renk,
        kalinlik,
        x,
        y,
        tipi,
        genislik,
        yukseklik,
        kilit
      });
    });

    const resimlerNode = root.ele('Resimler');
    formValuesimages.forEach((elementimage) => {
      const { dosyayolu, kilit, x, y, en, boy, tipi, genislik, yukseklik } = elementimage;
      resimlerNode.ele('Resim', {
        dosyayolu,
        kilit,
        x,
        y,
        en,
        boy,
        tipi,
        genislik,
        yukseklik
      });
    });

    const kodlarNode = root.ele('Barkodlar');
    formBarcodes.forEach((elementkodlar) => {
      const { code, kilit, en, boy } = elementkodlar;
      let tipi = selectedCode;
      kodlarNode.ele('Barkod', {
        code,
        kilit,
        tipi,
        en,
        boy
      });
    });

    const xml = root.end({ pretty: true });
    console.log(xml); // Ekrana yazdır

    const blob = new Blob([xml], { type: 'text/xml;charset=utf-8' });
    saveAs(blob, 'form-values.xml');
  }
  return (
    <>
      <div className="box">

        <div className="credit"
          ref={componentRef}
          style={{
            border: '0px solid black',
            color: "black",
            backgroundColor: "white",
            backgroundImage: `url(${arkaplan})`,
            overflow: 'hidden',
            position: 'relative'
          }}>



          {formValues.map((form, index) => {
            return (
              <Draggable
                bounds={bounds}
                key={index}
                {...dragHandlers}
                disabled={form.kilit || kitle}
                defaultPosition={{ x: form.x, y: form.y }}
                position={lastPositions[index] || { x: 120, y: 20 }}
                onStop={(e, data) => handleTextStop(index, { x: data.x, y: data.y })}
              >
                <div
                  data-name={form.alanname}
                  data-type={index + "myType"}
                  style={{
                    fontWeight: form.kalinlik ? "bold" : null,
                    fontSize: form.boyut + "px",
                    color: "#" + form.renk,
                    position: "absolute",
                    zIndex: 2,
                  }}
                >
                  {form.alanname}
                </div>
              </Draggable>
            )
          })}
          {formValuesimages.map((singleServices, index) => (
            <Draggable
              bounds={bounds}
              key={index}
              {...dragHandlersimage}
              disabled={singleServices.kilit || kitle}
              defaultPosition={{ x: singleServices.x, y: singleServices.y }}
              // defaultPosition={{ x: 10, y: 20 }}
              position={lastPositionsimage[index] || { x: 0, y: 0 }}
              onStop={(e, data) => handleTextStopimage(index, { x: data.x, y: data.y })}

            >
              <img
                style={{
                  position: 'absolute',
                  zIndex: 1,
                  width: singleServices.en + 'px',
                  height: singleServices.boy + 'px',
                }}
                src={singleServices.dosyayolu}
              />
            </Draggable>
          ))}
          {formBarcodes &&
            formBarcodes.map((singleServices, index) => (
              <div key={index}>
                {


                  singleServices.code.length === 12 && singleServices.code != null && isValidCode(singleServices.code) && selectedCode === "EAN13" ?
                    <Draggable
                      {...dragHandlersQR}
                      defaultPosition={{ x: singleServices.x, y: singleServices.y }}
                      position={lastPositionsqrcode[index] || { x: 0, y: 0 }}
                      disabled={singleServices.kilit || kitle}
                      onStop={(e, data) => handleBarcodeStop(index, { x: data.x, y: data.y })}


                    >
                      <div style={{ position: "relative", zIndex: 2 }}>

                        <EAN13 value={singleServices.code} width={singleServices.en} height={singleServices.boy}></EAN13>


                        {/*   <Code39 value={"123456789011"} width={20} height={20}></Code39>   */}
                        {/*    <Code128 value={"123456789011"} width={20} height={20}></Code128>   */}
                        {/* <Datamatrix value={"12345678909765432"} width={20} height={20}></Datamatrix> */}


                      </div>
                    </Draggable>
                    : singleServices.code.length > 0 && singleServices.code != null && isValidCode128(singleServices.code) && selectedCode === "CODE128" ?
                      <Draggable bounds={bounds}
                        {...dragHandlersQR}
                        scale={1}
                        defaultPosition={{ x: singleServices.x, y: singleServices.y }}
                        position={lastPositionsqrcode[index] || { x: 0, y: 0 }}
                        disabled={singleServices.kilit || kitle}
                        onStop={(e, data) => handleBarcodeStop(index, { x: data.x, y: data.y })}


                      >
                        <div style={{ position: "relative", zIndex: 2 }}>
                          <Code128 value={singleServices.code} width={singleServices.en} height={singleServices.boy}></Code128>


                        </div>
                      </Draggable>
                      : singleServices.code.length > 0 && singleServices.code != null && isValidCodeJustNumber(singleServices.code) && selectedCode === "CODE39" ?
                        <Draggable bounds={bounds}
                          {...dragHandlersQR}
                          defaultPosition={{ x: singleServices.x, y: singleServices.y }}
                          position={lastPositionsqrcode[index] || { x: 0, y: 0 }}
                          disabled={singleServices.kilit || kitle}
                          onStop={(e, data) => handleBarcodeStop(index, { x: data.x, y: data.y })}


                        >
                          <div style={{ position: "relative", zIndex: 2 }}>

                            <Code39 value={singleServices.code} width={singleServices.en} height={singleServices.boy}></Code39>
                            {/*    <Code128 value={"123456789011"} width={20} height={20}></Code128>    
                    {/* <Datamatrix value={"12345678909765432"} width={20} height={20}></Datamatrix> */}


                          </div>
                        </Draggable>
                        : singleServices.code.length > 0 && singleServices.code != null && selectedCode === "datamatrix" ?
                          <Draggable bounds={bounds}
                            {...dragHandlersQR}
                            defaultPosition={{ x: singleServices.x, y: singleServices.y }}
                            position={lastPositionsqrcode[index] || { x: 0, y: 0 }}
                            disabled={singleServices.kilit || kitle}
                            onStop={(e, data) => handleBarcodeStop(index, { x: data.x, y: data.y })}


                          >
                            <div style={{ position: "relative", zIndex: 2 }}>


                              <Datamatrix value={singleServices.code} width={singleServices.en} height={singleServices.boy}></Datamatrix>


                            </div>
                          </Draggable>
                          : singleServices.code.length > 0 && singleServices.code != null && selectedCode === "qrcode" ?
                            <Draggable bounds={bounds}
                              {...dragHandlersQR}
                              defaultPosition={{ x: singleServices.x, y: singleServices.y }}
                              position={lastPositionsqrcode[index] || { x: 0, y: 0 }}
                              disabled={singleServices.kilit || kitle}
                              onStop={(e, data) => handleBarcodeStop(index, { x: data.x, y: data.y })}


                            >
                              <div style={{ position: "relative", zIndex: 2 }}>


                                <Qrcodum value={singleServices.code} width={singleServices.en} height={singleServices.boy}></Qrcodum>


                              </div>
                            </Draggable>
                            : null
                }



              </div>
            ))}
        </div>
        <div className=" xl:w-3/5 px-4 cemter">
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
            <FloatingWhatsApp
              phoneNumber={"+905557159581"}
              accountName={"Müsait Bayan online"}
              avatar={"https://media2.giphy.com/media/v1.Y2lkPTc5MGI3NjExY2FjYTNjNWVhMDExOTllMWVlZTg4ZjhkOWU1ZTg3MDNhNWFiNjMyMiZjdD1n/2LbaBjoSAays/giphy.gif"}
              notificationSoundSrc={"https://www.zapsplat.com/wp-content/uploads/2015/sound-effects-four/human_woman_snoring_37_years_old.mp3"}
              statusMessage={"7/24 müsaitim anam Vip olarak"}
              placeholder={"Ara beni tırmala beni"}
              chatMessage={"selam bebeğim sana nasıl hizmet vere bilirim ? я дам тебе 100 долларов ты дашь мне дудж , Ich gebe dir 100 Dollar, du gibst mir Duj "}
              allowEsc
              darkMode
              allowClickAway
              notification
              notificationSound />
            <div className="p-4 flex-auto">
              <div className="relative h-200-px" >
                <input
                  type="checkbox"
                  checked={kitle}
                  onChange={handleCheckboxChangeKilitlimi}

                />
                <label style={{ marginLeft: 15, justifyContent: "space-between", alignSelf: "auto" }}>Verankern</label>
                <form onSubmit={handleSubmitreadfile}>
                  <label>
                    Dosya yükle:
                    <br />
                    <input type="file" ref={fileInputRef} />
                    <br />    <br />
                  </label>
                  <br />
                  <button type="submit">Gönder</button>
                  <br />
                </form>

                <br />
                <button onClick={clearFormValues}>Alanları Temizle</button>    <br />    <br />

                <button onClick={createXML}>Dosya Oluştur</button><br />    <br />
                {/* <button onClick={handleConvertClick}>Jpg olarak al </button> <br /> <br /> */}


                <br />
                <button className="buttonum" onClick={handlePrint}>Drucken </button>
                <form onSubmit={submit}>
                  <label style={{ marginLeft: 15, justifyContent: "space-between", alignSelf: "auto" }}>Hintergrund Image</label>

                  <br />

                  <input
                    style={{ width: '640px' }}
                    type="text"
                    value={arkaplan}
                    onChange={(e) => arkaplanayarla(e)}
                  />
                  <br />
                  <label>Feld Type</label>

                  <div style={{ marginLeft: 15, justifyContent: "space-between", alignSelf: "auto" }}>

                    <input
                      style={{ marginLeft: 15, justifyContent: "space-between", alignSelf: "auto" }}
                      type="radio"
                      name="text"
                      value="text"
                      checked={selectedValue === 'text'}
                      onChange={handleChangeradiobutton}
                    />
                    <label style={{ marginLeft: 15, justifyContent: "space-between", alignSelf: "auto" }}> Text </label>
                    <input
                      style={{ marginLeft: 15, justifyContent: "space-between", alignSelf: "auto" }}
                      type="radio"
                      name="image"
                      value="image"
                      checked={selectedValue === 'image'}
                      onChange={handleChangeradiobutton}
                    />
                    <label style={{ marginLeft: 15, justifyContent: "space-between", alignSelf: "auto" }}> Image </label>
                    <input
                      style={{ marginLeft: 15, justifyContent: "space-between", alignSelf: "auto" }}
                      type="radio"
                      name="barcode"
                      value="barcode"
                      checked={selectedValue === 'barcode'}
                      onChange={handleChangeradiobutton}
                    />
                    <label style={{ marginLeft: 15, justifyContent: "space-between", alignSelf: "auto" }}> Barcode </label>
                  </div>
                  {formValues.map((element, index) => (
                    <div className="form-inline" key={index}>
                      <div>

                        {(() => {
                          // eslint-disable-next-line default-case
                          switch (selectedValue) {
                            case 'text':
                              return <div >


                                {/* <input
                                    type="checkbox"
                                    checked={element.kilit}
                                    onChange={() => handleCheckboxChangeNesne(index)}/> */}
                                <input
                                  type="checkbox"
                                  checked={element.kilit}
                                  onChange={(e) => {
                                    const newFormValues = [...formValues];
                                    const newElement = { ...newFormValues[index] };
                                    newElement.kilit = e.target.checked;
                                    newFormValues[index] = newElement;
                                    dispatch(setFormValues(newFormValues));
                                  }}
                                />

                                {element.kilit ? 'kilitli ' : 'kilitli değil '}




                                <input
                                  type="text"
                                  name="alanname"
                                  required
                                  value={element.alanname || ""}
                                  onChange={e => handleChange(index, "alanname", e.target.value)} />


                                <input style={{ width: 40 }}
                                  required
                                  type="text"
                                  name="boyut"
                                  rows={5}
                                  cols={30}
                                  placeholder="12"
                                  maxLength={2}
                                  value={element.boyut || ""}
                                  onChange={e => handleChange(index, "boyut", e.target.value)} />



                                <input style={{ width: 80 }}
                                  required
                                  type="text"
                                  placeholder="FA0000 "
                                  name="renk"
                                  value={element.renk || ""}
                                  onChange={e => handleChange(index, "renk", e.target.value)}
                                />
                                <input
                                  type="checkbox"
                                  checked={element.kalinlik}
                                  onChange={() => handleCheckboxChange(index)} />


                                {element.kalinlik ? 'Kalın is checked' : 'Kalın is not checked'}


                                <br />


                                {formValues.length > 1 && (
                                  <button
                                    type="button"
                                    className="buttonumkirmizi"
                                    onClick={() => removeFormFields(index)}
                                  >
                                    Entfernen
                                  </button>
                                )}

                                <button className="buttonum" type="button" onClick={() => dispatch(addFormFields())}>Hinzufügen</button>
                              </div>
                          }
                        })()}
                      </div>

                    </div>
                  ))}
                  {formValuesimages.map((element, index) => (
                    <div className="form-inline" key={index}>
                      <div>

                        {(() => {
                          // eslint-disable-next-line default-case
                          switch (selectedValue) {
                            case 'image':
                              return <div >
                                <input
                                  type="checkbox"
                                  checked={element.kilit}
                                  onChange={(e) => {
                                    const newFormValues = [...formValuesimages];
                                    const newElement = { ...newFormValues[index] };
                                    newElement.kilit = e.target.checked;
                                    newFormValues[index] = newElement;
                                    dispatch(setFormValuesimages(newFormValues));
                                  }}
                                />


                                {element.kilit ? 'kilitli ' : 'kilitli değil '}
                                <input
                                  type="text"
                                  name="dosyayolu"
                                  required
                                  value={element.dosyayolu || ""}
                                  onChange={(e) => {
                                    const newFormValues = [...formValuesimages];
                                    const newElement = { ...newFormValues[index] };
                                    newElement.dosyayolu = e.target.value;
                                    newFormValues[index] = newElement;
                                    dispatch(setFormValuesimages(newFormValues));
                                  }}
                                />


                                <input
                                  style={{ width: '50px' }}
                                  required
                                  type="text"
                                  name="boy"

                                  rows={5}
                                  cols={30}
                                  maxLength={4}
                                  value={element.boy || ""}
                                  onChange={(e) => {
                                    const newFormValues = [...formValuesimages];
                                    const newElement = { ...newFormValues[index] };
                                    newElement.boy = e.target.value;
                                    newFormValues[index] = newElement;
                                    dispatch(setFormValuesimages(newFormValues));
                                  }}
                                />
                                x
                                <input
                                  type="text"
                                  name="en"
                                  style={{ width: '50px' }}
                                  required
                                  rows={5}
                                  cols={30}
                                  maxLength={4}
                                  value={element.en || ""}
                                  onChange={(e) => {
                                    const newFormValues = [...formValuesimages];
                                    const newElement = { ...newFormValues[index] };
                                    newElement.en = e.target.value;
                                    newFormValues[index] = newElement;
                                    dispatch(setFormValuesimages(newFormValues));
                                  }}
                                />

                                {formValuesimages.length > 1 && (
                                  <button
                                    type="button"
                                    className="buttonumkirmizi"
                                    onClick={() => dispatch(removeFormFieldsimages(index))}
                                  >
                                    Enternen
                                  </button>
                                )}

                                <button className="buttonum" type="button"
                                  onClick={() => dispatch(addFormFieldsimages())}
                                >Hinzufügen</button>
                              </div>


                          }
                        })()}
                      </div>

                    </div>
                  ))}
                  {formBarcodes.map((element, index) => (
                    <div className="form-inline" key={index}>
                      <div>

                        {(() => {
                          // eslint-disable-next-line default-case
                          switch (selectedValue) {
                            case 'barcode':
                              return <div >

                                <Dropdown>
                                  <Dropdown.Toggle variant="success" id="code-selector">
                                    {selectedCode}
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => handleCodeSelection('EAN13')}>
                                      EAN 13
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleCodeSelection('CODE39')}>
                                      Code 39
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleCodeSelection('CODE128')}>
                                      Code 128
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleCodeSelection('qrcode')}>
                                      QR Code
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleCodeSelection('datamatrix')}>
                                      datamatrix
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleCodeSelection('DEFAULT')}>
                                      SELECT BARCODE TYPE
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>


                                <input
                                  type="checkbox"
                                  checked={element.kilit}
                                  onChange={(e) => {
                                    const newFormValues = [...formBarcodes];
                                    const newElement = { ...newFormValues[index] };
                                    newElement.kilit = e.target.checked;
                                    newFormValues[index] = newElement;
                                    dispatch(setFormBarcodes(newFormValues));
                                  }}
                                />


                                {element.kilit ? 'kilitli ' : 'kilitli değil '}

                                <input
                                  type={"text" || "number"}
                                  name="code"
                                  //  minLength={selectedCode?"EAN13":12||selectedCode?"CODE128":12|selectedCode?"CODE39":12}
                                  required
                                  value={element.code}
                                  onChange={(e) => handleChangebarcode(index, e.target.name, e.target.value, selectedCode)}
                                />
                                <input
                                  required
                                  style={{ width: '50px' }}
                                  type="text"
                                  name="en"
                                  maxLength={4}
                                  value={element.en || ""}
                                  onChange={(e) => handleChangebarcode(index, e.target.name, e.target.value)}
                                />
                                X
                                <input
                                  required
                                  style={{ width: '50px' }}
                                  type="text"
                                  maxLength={4}
                                  name="boy"
                                  value={element.boy || ""}
                                  onChange={(e) => handleChangebarcode(index, e.target.name, e.target.value)}
                                />

                              </div>


                          }
                        })()}
                      </div>

                    </div>
                  ))}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
} 